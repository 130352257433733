import React from 'react';
import { useDispatch } from 'react-redux';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import {  offline_enable, setLoginResponse, test_environment_mode } from '../constants/constant';
import Dashboard from './dashboard/Dashboard';
import Login from './loginForm/Login';
import SsoLogin from './loginForm/SsoLogin';
import { setCartType, setActiveShopHash } from "../actions";
import EcartWeb from './theme/EcartWeb';
import Configuration from './configuration/Configuration';
import Admin from './admin/Admin';
import Signup from './admin/Signup';
import PrintBill from './billing/PrintBill';
import LoginFormNew from './loginForm/LoginFormNew';

// class Routing extends React.Component {

const Routing = () => {


    // render() {
    const dispatch = useDispatch();
    const loginData = JSON.parse(sessionStorage.getItem('carInitialState'))
    if (loginData) {
        //console.log("Routing loginData route",loginData);
        setLoginResponse(loginData, loginData.passWord);
        dispatch(setCartType(loginData.cartType));
        dispatch(setActiveShopHash(loginData.activeShopHash));
    }

    return (
        <HashRouter>
            <Routes>
                 <Route exact path="/login" element={<Login />} />
                {
                    offline_enable && <Route exact path="/sso-login" element={<SsoLogin />} />
                }
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/printBill" element={<PrintBill />} />
                {
                    test_environment_mode && <Route exact path="/testsocket" element={<Configuration />} />
                }
                <Route exact path="/shop" element={<EcartWeb />} />
                <Route exact path="/admin-snp" element={<Admin />} />
                <Route exact path="/signup" element={<Signup />} />
                <Route exact path="/loginnew" element={<LoginFormNew />} />

                <Route exact path="/" element={
                    offline_enable ? <Navigate replace to="/sso-login" /> :
                        <Navigate replace to="/login" />} />
            </Routes>



            {/* <Route exact path="/error" element={
                        <Alert
                            severity={"error"}
                            alertcontent={"ERROR ALERT"}
                            alertDetail={"Something went wrong."}
                            alertSecondaryDetail={"Please try again after sometime."}
                        />} /> */}

        </HashRouter>
    );
}
// }


export default Routing;