import React, { useEffect, useLayoutEffect, useState } from "react";
import { Grid, Paper, Typography, TextField, Autocomplete, Box, Tooltip } from "@mui/material";
import Loader from "../global/loader/Loader";
import {
    SHOWUSERTABLE,
    getPreUrlAccordingToCart,
    SUCCESS,
    CART_INITIAL_STATE,
    GETBILLBYREGEX,
    InternalBillType,
    isRoleAllowed,
    SALESVIEW,
    SHOWALLBILLS,
    BillStatus,
    SALESRIGHTS,
    DashboardComponent,
    TABLEOWNERRIGHTS,
    QUICKBILLING,
    GETSINGLECONFIGS,
    GET_TODAYBALANCE_DATA,
    TODAYBALVIEWINDASHBOARD,
    ADMINRIGHTS,
    OTHSALESRIGHTS,
    printDebugLog,
    printInfoLog,
    TRIP_RUNNING,
    isFeatureAllowed,
    RETURNWASTEMGMT,
    COMPONENT_PARAMS,
    GETVIDEOPATH,
    GETBILLTYPEDETAILS
} from "../../constants/constant";
import {
    Button,
} from "@mui/material";
import "./BillingModule.css";
import { useSelector } from "react-redux";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { isEmpty } from "lodash";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CompressIcon from '@mui/icons-material/Compress';
import { snpAxios } from "../global/api";
import axios from "axios";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import DialogBox from "../global/DialogBox";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SingleFab from "../global/fab/SingleFab";
import YouTubeVideo from "../global/YouTubeVideo";
import { SCREEN_DASHBOARD_RESTO } from "../../constants/VideoConstant";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
export default function BillingTable({ goToBilling, setComponentIndex, showDialogBoxWithPaper }) {
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [listAcc, setListAcc] = useState([]);
    const [todayBalData, setTodayBalanceData] = useState({
        balance: 0.0,
        expense: 0.0,
        dues: 0.0,
    });
    const [mAccType, setAccType] = useState(CART_INITIAL_STATE.defBalanceType);
    const [accDialog, setAccDialog] = useState(false);
    const [accDialogLoader, setAccDialogLoader] = useState(false);
    const [tableData, setTableData] = useState([]);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);

    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 767px)').matches);
    const [videoModel, setVideoModel] = useState({ show: false, path: "" });

    const INITIAL_BILL_TYPE = {
        booked_table_count: 0.0,
        free_table_count: 0.0,
        total_bill: 0.0,
        yesterday_bill: 0.0,
        todays_bill: 0.0,
        waste_total_count: 0.0,
        return_total_count: 0.0,
        take_away_total_count: 0.0,
        take_away_paid_count: 0.0,
        home_total_count: 0.0,
        home_paid_count: 0.0,
    };

    const [billTypeDetails, setBillTypeDetails] = useState(INITIAL_BILL_TYPE);

    const getTableData = async () => {
        let custUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + SHOWUSERTABLE;
        let data = {
            jcJson: {
                value: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            },
        };
        //   console.log("verifyCredentials => ",data);
        setDataLoaded(false);
        snpAxios
            .post(custUrl, data)
            .then((response) => {
                //console.log("getTableData => ", response.data);
                setDataLoaded(true);
                setGrid(InternalBillType.BookTable);
                if (response.data.status === SUCCESS) {
                    setTableData(response.data.jcJson.value_2);
                } else
                    setTableData([]);
            })
            .catch((error) => {
                setDataLoaded(true);
                console.log("Error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getBalData = async (accType) => {
        let custUrl =
            storeApiUrl.BACKEND_BASE_URL + GET_TODAYBALANCE_DATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                accountType: accType,
            },
        };
        //   console.log("getBalData => ",data);
        snpAxios
            .post(custUrl, data)
            .then((response) => {
                //console.log("getBalData => ", response.data);
                setAccDialogLoader(false);
                if (response.data.status === SUCCESS) {
                    setTodayBalanceData({
                        ...todayBalData,
                        balance: response.data.jcJson.balance,
                        expense: response.data.jcJson.expense,
                        dues: response.data.jcJson.dues,
                    })
                    setListAcc(response.data.jcJson.listAcc);
                    setAccType(accType);
                }

            })
            .catch((error) => {
                setAccDialogLoader(false);
                console.log("Error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getBillTypeDetails = () => {
        setDataLoaded(false);
        let mUrl = storeApiUrl.BACKEND_BASE_URL + GETBILLTYPEDETAILS + "?token=" + CART_INITIAL_STATE.apiToken + "&shopHash=" + storeActiveShopHash.id;
        let data = {
        };
        axios.get(
            mUrl,
            data
        ).then(response => {
            setDataLoaded(true);
            printDebugLog("responses => ", response.data);
            if (response.data.status === SUCCESS) {
                setBillTypeDetails({
                    ...billTypeDetails, ...response.data.jcJson,
                })
            }

        }).catch(error => {
            setDataLoaded(true);
            printInfoLog("error : ", error);
            //window.location.href = "#/error";
        });
    }


    const [quickBillAllowed, setQuickBillingAllowed] = useState(false);
    const [todaysBalSheetAlwd, setTodayBalSheetAllowed] = useState(false);
    //True means my bill req , false means other bill req also
    const [billReq, setbillReq] = useState(true);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        //var currentTime = +new Date();
        printDebugLog("useEffect<=", CART_INITIAL_STATE);
        const handleMediaQueryChange = (event) => {
            setIsMobile(event.matches);
        };

        mediaQuery.addEventListener('change', handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);

    useLayoutEffect(() => {
        console.log('useLayoutEffect');
        COMPONENT_PARAMS.sBillNo = '';
        setGrid(0);
        getBillTypeDetails();

        getYoutubeVideo(SCREEN_DASHBOARD_RESTO, 'hi');
        getSingleConfigs(QUICKBILLING);
        getSingleConfigs(TODAYBALVIEWINDASHBOARD);
    }, [storeCartType, storeActiveShopHash, storeApiUrl]);


    const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&configName=${mConfigName}` + `&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //printLog("getSingleConfigs => ", cUrl);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    if (mConfigName === QUICKBILLING)
                        setQuickBillingAllowed(response.data === 'Yes');
                    if (mConfigName === TODAYBALVIEWINDASHBOARD) {
                        setTodayBalSheetAllowed(response.data === 'Yes');
                        if (response.data === 'Yes')
                            getBalData(mAccType);
                    }
                }
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getYoutubeVideo = (videoName, lang) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETVIDEOPATH + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&name=${videoName}` + `&lang=${lang}`;
        let data = {};
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    setVideoModel({ ...videoModel, path: response.data });
                } else
                    setVideoModel({ ...videoModel, path: '' });
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
                setVideoModel({ ...videoModel, path: '' });
            });
    };



    const handleBookTable = (singleData) => {
        //console.log("handleBookTable", singleData);
        // if (!isEmpty(singleData.assignedName) && singleData.assignedName !== CART_INITIAL_STATE.userName) {
        //     if (isRoleAllowed(OTHSALESRIGHTS)) {
        //         goToBilling(singleData, singleData.billNo, InternalBillType.BookTable);
        //     } else {
        //         showDialogBoxWithPaper("You Are not allowed to Change in Sales.Contact the Owner");
        //     }
        // } else {
        //     goToBilling(singleData, singleData.billNo, InternalBillType.BookTable);
        // }
        goToBilling(singleData, singleData.billNo, InternalBillType.BookTable);
    };
    const handleOrderBooked = (singleData) => {
        //console.log("handleOrderBooked", singleData,grid);
        goToBilling({}, singleData.billNo, grid);
    };

    const handleSelectBill = (ev, option) => {
        //console.log("handleSelectBill", option);
        goToBilling({}, option.billNo, -1);
    };

    const [listAllBill, setListAllBill] = useState([]);
    const [listShowBills, setListShowBills] = useState([]);


    const getAllBillDetails = async (mBillType) => {
        setDataLoaded(false);
        let url = storeApiUrl.BACKEND_BASE_URL + SHOWALLBILLS;

        let data = {
            jcJson: {
                shopHash: storeActiveShopHash.id,
                value: CART_INITIAL_STATE.apiToken,
                settleBill: false,
                billType: mBillType,
                myBillRequest: billReq,
            }
        };
        let val = !billReq;
        printDebugLog("getAllBillDetails", "Current Bill Req Status: " + billReq + " updating UI: " + val);
        setbillReq(val);

        snpAxios.post(
            url,
            data
        )
            .then((response) => {
                //console.log("response  => ", response.data);
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    setListShowBills(response.data.jcJson.data);
                }
            }).catch((error) => {
                setDataLoaded(true);
                printInfoLog("errr ", error);
                //window.location.href = "#/error";
            });
    };
    const fetchBill = async (regextStr) => {
        let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + GETBILLBYREGEX + "?adminId=" + CART_INITIAL_STATE.apiToken + "&shopHash=" + storeActiveShopHash.id + "&regexStr=" + regextStr;
        //console.log("fetchBill=> ",fetchBillUrl);
        let data = {};
        snpAxios.post(
            fetchBillUrl,
            data
        )
            .then((response) => {
                //console.log("fetchBill   => ", response.data);
                if (response.data.status === SUCCESS) {
                    setListAllBill(response.data.jcJson.listBills);
                }
            }).catch((error) => {
                console.log("errr ", error);
            });
    };

    const handleBillSearch = (event) => {
        const { name, value } = event.target;
        //console.log("handleBillSearch",name,value)
        if (value.length >= 4) {
            fetchBill(value);
        }
    };

    const getSearchSelectDetails = (option) => {
        let data = "";
        //console.log("getSearchSelectDetails=>",option)
        if (option) {
            data = option.billNo;
            if (option.custMob)
                data = data + "(" + option.custMob + ")";
        }
        return data;
    };

    const [grid, setGrid] = useState(0);

    const handleButtonGrid = (index) => {
        //console.log("handleButtonGrid",index);
        if (todaysBalSheetAlwd)
            getBalData(mAccType);
        if (index === InternalBillType.BookTable) {
            if (isRoleAllowed(SALESRIGHTS) || isRoleAllowed(TABLEOWNERRIGHTS)) {
                getTableData(0, 5);
            } else {
                showDialogBoxWithPaper("You Are not allowed to Change in Sales.Contact the Owner");
            }
        } else if (index === InternalBillType.Show) {
            if (isRoleAllowed(SALESVIEW)) {
                setComponentIndex(DashboardComponent.SHOWBILLS);
            } else {
                showDialogBoxWithPaper("You Are not allowed to View Sales.Contact the Owner");
            }
        } else {
            if (isRoleAllowed(SALESRIGHTS)) {
                setListShowBills([]);
                setGrid(index);
                getAllBillDetails(index);
            } else {
                showDialogBoxWithPaper("You Are not allowed to Change in Sales.Contact the Owner");
            }
        }
    };

    const getTableDetsName = (level, data) => {
        //console.log("getTableDetsName=>", data);
        if (level === 1) {
            let resp = "";
            if (!isEmpty(data.tableName))
                resp = data.tableName + "(" + data.tableNo + ")";
            else
                resp = "T no." + data.tableNo;
            if (!isEmpty(data.finalPrice))
                resp = resp + "\n" + "₹" + data.finalPrice;
            return resp;
        }
        if (level === 2 && data.lapsedTime)
            return data.lapsedTime;
        if (level === 3) {
            let resp = "";
            if (data.assignedName) //Table Owner Name
                resp = "@ " + data.assignedName;
            if (data.saleName) //Cashier Owner Name
                resp = resp + "\n# " + data.saleName;
            if (data.custName) //Cust Name
                resp = resp + "\nCust:" + data.custName;
            return resp;
        }
        return "        ";
    };




    const getBillDets = (level, data) => {
        //printLog("getBillDets=>", data);
        if (level === 1) {
            let dets = data.billNo;
            if (data.delvName)
                dets = dets + "(" + data.delvName + ")";
            return dets;
        } if (level === 2)
            return "Order no." + data.token + "(₹" + data.finalPrice + ")";
        if (level === 3 && data.lapsedTime)
            return data.lapsedTime;
        if (level === 4) {
            //console.log("getBillDets=> here", level,data,data.billAddBy,CART_INITIAL_STATE.userName);
            let resp = "";
            if (data.billAddBy && data.billAddBy !== CART_INITIAL_STATE.userName)
                resp = "@ " + data.billAddBy;
            if (data.custName) {
                if (resp)
                    resp = resp + "\n" + "Cust:" + data.custName;
                else
                    resp = "Cust:" + data.custName;
            }
            if (data.tripDetails)
                resp = resp + "\nTrip Status: " + data.tripDetails;
            return resp;
        }
        return "        ";
    };

    const paidItemColor = (data) => {
        if (data.billNo === "0O-225") {
            console.log("paidItemColor");
        }
        if (data.billStatus === BillStatus.PAID
            && isEmpty(data.tripDetails))
            return true;
        if (data.billStatus === BillStatus.HOMEDELIVERYPAY
            && !isEmpty(data.tripDetails) && data.tripDetails === TRIP_RUNNING)
            return true;
        return false;
    };

    const renderShowOrder = () => {
        return (
            <>
                <div>
                    <Grid container spacing={0} display="flex"
                        alignItems="center" sx={{
                            padding: 0.1, mt: 2,
                        }}>
                        <Grid item xs={12}>
                            <Paper
                                elevation={5}
                                autoHeight
                                sx={{
                                    display: "grid",
                                    flexDirection: "row",
                                    backgroundColor: grid === InternalBillType.TakeAway ? "#bfb2ce" : "#bdecf7",
                                    borderRadius: 5,
                                    padding: 1,
                                }}>
                                <Grid container spacing={1}
                                    sx={{justifyContent:"center",alignItems:"center",alignContent:"center"}}>
                                    <Grid item md={2} xs={6} sm={6}>
                                        <Tooltip title="Go Back">
                                            <center>
                                                <KeyboardBackspaceIcon style={{ fontSize: 70 }} onClick={() => {
                                                    setGrid(0);
                                                    setbillReq(true); //default
                                                }} />
                                            </center></Tooltip>
                                    </Grid>
                                    <Grid item md={2} xs={12} sm={6}>
                                        <center>
                                        <SnpButton
                                            variant="contained"
                                            color="primary"
                                            style={{ float: "center" }}
                                            onClick={() => handleOrderBooked({})}
                                        >
                                            Add New Order
                                        </SnpButton>
                                        </center>
                                    </Grid>

                                    <Grid item md={3} xs={12} sm={6}>
                                        <center>
                                        <Typography variant="h3" >
                                            Order Status
                                        </Typography>
                                        </center>
                                    </Grid>
                                    {
                                        (isRoleAllowed(ADMINRIGHTS) || isRoleAllowed(OTHSALESRIGHTS))
                                        && <Grid item md={2} xs={12} lg={2} sm={4}>
                                            <center>
                                            <SnpButton
                                                variant="contained"
                                                mBgColor="#543d4e"
                                                style={{ float: "center" }}
                                                onClick={() => handleButtonGrid(grid)}
                                            >
                                                {!billReq ? "Other Bills also" : "Only My Bills"}
                                            </SnpButton></center>
                                        </Grid>
                                    }
                                </Grid></Paper></Grid>
                        {
                            listShowBills.length > 0 &&
                            [...listShowBills].map(data => (
                                <Grid item md={2} xs={6} sm={4} sx={{
                                    padding: 0, mt: 2,
                                }}>
                                    <Paper
                                        elevation={0}
                                        autoHeight
                                        onClick={() => handleOrderBooked(data)}
                                        className="showbills-data-container"
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: paidItemColor(data) ? "#a1b487" : (data.billStatus === BillStatus.PARTIALLYPAID) ? "#f08888" : "white",
                                        }}>
                                        <div style={{
                                            whiteSpace: 'pre-wrap', overflowWrap: 'break-word', textAlign: 'center',
                                            justifyContent: 'center', alignItems: 'center'
                                        }}>
                                            <p style={{
                                                whiteSpace: 'pre-wrap', overflowWrap: 'break-word',
                                                fontSize: '10px', justifyContent: 'center', alignItems: 'center', alignContent: 'center'
                                            }}>{getBillDets(1, data)}</p>
                                            <center>
                                                <p style={{
                                                    whiteSpace: 'pre-wrap', overflowWrap: 'break-word', textAlign: 'center',
                                                    fontSize: '12px', justifyContent: 'center', alignItems: 'center'
                                                }}><b>{getBillDets(2, data)}</b></p>
                                            </center>
                                            <p style={{
                                                whiteSpace: 'pre-wrap', overflowWrap: 'break-word',
                                                fontSize: '10px', justifyContent: 'center', alignItems: 'center', alignContent: 'center'
                                            }}>{getBillDets(3, data)}</p>
                                            <p style={{
                                                whiteSpace: 'pre-wrap', overflowWrap: 'break-word',
                                                fontSize: '10px', justifyContent: 'center', alignItems: 'center', alignContent: 'center'
                                            }}>{getBillDets(4, data)}</p>
                                        </div>

                                    </Paper>
                                </Grid>
                            ))

                        }
                    </Grid>

                </div>


            </>
        );
    };

    const renderTableGrid = () => {
        return (
            <>
                <div>
                    <Grid container spacing={1}
                        display="flex"
                        alignItems="center" sx={{
                            padding: 0.1, mt: 2,
                        }}>
                        <Grid item xs={12}>
                            <Paper
                                elevation={5}
                                autoHeight
                                sx={{
                                    display: "grid",
                                    flexDirection: "row",
                                    backgroundColor: "rgb(252, 221, 165)",
                                    borderRadius: 5,
                                    padding: 1,
                                }}>
                                <Grid container spacing={1}
                                    sx={{justifyContent:"center",alignItems:"center",alignContent:"center"}}>
                                    <Grid item md={2} xs={6} sm={6}>
                                        <Tooltip title="Go Back">
                                            <center>
                                                <KeyboardBackspaceIcon style={{ fontSize: 70 }} onClick={() => {
                                                    setGrid(0);
                                                    setbillReq(true); //default
                                                }} />
                                            </center></Tooltip>
                                    </Grid>
                                    
                                    <Grid item md={3} xs={12} sm={6}>
                                        <center>
                                            <Typography variant="h3" >
                                                Book Table
                                            </Typography>
                                        </center>
                                    </Grid>
                                    <Grid item md={2} xs={12} sm={6}>
                                        <center>
                                            <SnpButton
                                                variant="contained"
                                                color="primary"
                                                style={{ float: "center" }}
                                                onClick={() => setComponentIndex(DashboardComponent.TABLECONFIG)}
                                            >
                                                Table Configuration
                                            </SnpButton></center>
                                    </Grid>
                                </Grid>
                            </Paper></Grid>
                        {
                            tableData.length > 0 &&
                            [...tableData].map(data => (
                                <Grid item md={1.5} xs={6} sm={4} sx={{
                                    padding: 0, mt: 2,
                                }}>
                                    <Paper
                                        elevation={0}
                                        autoHeight
                                        onClick={() => handleBookTable(data)}
                                        className="table-data-container"
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: data.tableBooked ? "orange" : "white",
                                        }}>
                                        <div style={{
                                            whiteSpace: 'pre-wrap', overflowWrap: 'break-word', textAlign: 'center',
                                            fontSize: '14px', justifyContent: 'center', alignItems: 'center'
                                        }}>
                                            <p style={{
                                                whiteSpace: 'pre-wrap', overflowWrap: 'break-word',
                                                fontSize: '10px', justifyContent: 'center', alignItems: 'center', alignContent: 'center'
                                            }}>{getTableDetsName(2, data)}</p>
                                            <center>
                                                <p style={{
                                                    whiteSpace: 'pre-wrap', overflowWrap: 'break-word', textAlign: 'center',
                                                    fontSize: '14px', justifyContent: 'center', alignItems: 'center'
                                                }}><b>{getTableDetsName(1, data)}</b></p>
                                            </center>
                                            <p style={{
                                                whiteSpace: 'pre-wrap', overflowWrap: 'break-word',
                                                fontSize: '10px', justifyContent: 'center', alignItems: 'center', alignContent: 'center'
                                            }}>{getTableDetsName(3, data)}</p>
                                        </div>
                                    </Paper>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
            </>
        );
    };
    const renderButtonGrid = () => {
        return (
            <>
                <Grid container spacing={2} sx={{
                    padding: 0.1, m: 2,
                }}>
                    <Grid item md={2} xs={12} sm={6} lg={2} spacing={2}>
                        <Paper
                            elevation={0}
                            autoHeight
                            onClick={() => handleButtonGrid(InternalBillType.BookTable)}
                            className="button-bill-data-container"
                            sx={{
                                borderRadius: 4,
                                margin: "4px",
                                backgroundColor: "rgb(252, 221, 165)",
                            }}>

                            <center>
                                <img alt="bt" style={{ width: "65px", height: "65px" }} src="./book_table.png" />
                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontWeight: "bold" }}>Book Table</p>
                            </center>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "18px" }}>*Occupied: {billTypeDetails.booked_table_count}</p>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "18px" }}>*Free: {billTypeDetails.free_table_count}</p>
                        </Paper>
                    </Grid>
                    <Grid item md={2} lg={2} xs={12} sm={6}>
                        <Paper
                            elevation={0}
                            autoHeight
                            onClick={() => handleButtonGrid(InternalBillType.TakeAway)}
                            className="button-bill-data-container"
                            sx={{
                                borderRadius: 4,
                                margin: "4px",
                                backgroundColor: "#bfb2ce",
                            }}>

                            <center>
                                <img alt="ta" style={{ width: "65px", height: "65px" }} src="./take_away.png" />
                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontWeight: "bold" }}>Take Away</p>
                            </center>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "18px" }}>*Initiated(kot): {billTypeDetails.take_away_total_count}</p>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "18px" }}>*Paid(Not Settle): {billTypeDetails.take_away_paid_count}</p>
                        </Paper>
                    </Grid>
                    {
                        quickBillAllowed &&
                        <Grid item md={2} lg={2} xs={12} sm={6}>
                            <Paper
                                elevation={0}
                                autoHeight
                                onClick={() => handleButtonGrid(InternalBillType.Quick)}
                                className="button-bill-data-container"
                                sx={{
                                    borderRadius: 4,
                                    margin: "4px",
                                    backgroundColor: "white",
                                }}>

                                <center>
                                    <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Quick Billing</p>
                                </center>
                            </Paper>
                        </Grid>
                    }
                    <Grid item md={2} lg={2} xs={12} sm={6}>
                        <Paper
                            elevation={0}
                            autoHeight
                            onClick={() => handleButtonGrid(InternalBillType.Home)}
                            className="button-bill-data-container"
                            sx={{
                                borderRadius: 4,
                                margin: "4px",
                                backgroundColor: "#bdecf7",
                            }}>

                            <center>
                                <img alt="hw" style={{ width: "65px", height: "65px" }} src="./home_delivery.png" />
                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontWeight: "bold" }}>Home Delivery</p>
                            </center>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "18px" }}>*Initiated(kot): {billTypeDetails.home_total_count}</p>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "18px" }}>*Paid(Not Settle): {billTypeDetails.home_paid_count}</p>
                        </Paper>
                    </Grid>
                    {
                        isFeatureAllowed(RETURNWASTEMGMT) && <Grid item md={2} xs={12} sm={6}>
                            <Paper
                                elevation={0}
                                autoHeight
                                onClick={() => setComponentIndex(DashboardComponent.RETURNWASTE)}
                                className="button-bill-data-container"
                                sx={{
                                    borderRadius: 4,
                                    margin: "4px",
                                    backgroundColor: "rgb(247, 188, 186)",
                                }}>

                                <center>
                                    <img alt="hw" style={{ width: "65px", height: "65px" }} src="./return_waste.png" />
                                    <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontWeight: "bold" }}>Return Billing/Waste Billing</p>
                                </center>
                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "17px" }}>*Today's Waste B.: {billTypeDetails.waste_total_count}</p>
                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "17px" }}>*Today's Return B.: {billTypeDetails.return_total_count}</p>
                            </Paper>
                        </Grid>
                    }

                    <Grid item md={2} lg={2} xs={12} sm={6}>
                        <Paper
                            elevation={0}
                            autoHeight
                            onClick={() => handleButtonGrid(InternalBillType.Show)}
                            className="button-bill-data-container"
                            sx={{
                                borderRadius: 4,
                                margin: "4px",
                                backgroundColor: "#c5ecbc",
                            }}>

                            <center>
                                <img alt="hw" style={{ width: "65px", height: "65px" }} src="./show_bill.png" />
                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontWeight: "bold" }}>Show Bills</p>
                            </center>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "17px" }}>*Today: {billTypeDetails.todays_bill}</p>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "17px" }}>*Yesterday: {billTypeDetails.yesterday_bill}</p>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "17px" }}>*Total: {billTypeDetails.total_bill}</p>
                        </Paper>
                    </Grid>

                </Grid>
            </>
        );
    };
    const renderGrid = () => {
        if (grid === 0)
            return renderButtonGrid();
        else if (grid === InternalBillType.Quick || grid === InternalBillType.Home || grid === InternalBillType.TakeAway)
            return renderShowOrder();
        else if (grid === InternalBillType.BookTable)
            return renderTableGrid();
        return null;
    };

    const renderDialogListAcc = () => {
        return (
            <>
                {
                    accDialogLoader ? <Loader /> :
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                            {
                                listAcc.map(item => (

                                    <Typography component="div" fontSize={28} variant="p" color={mAccType === item ? "#350404" : "#2f1160"} fontWeight={mAccType === item ? 'bold' : 'normal'} sx={{
                                        mb: "2px", mr: "15px", ml: "15px", justifyContent: "center"
                                    }}>{item} <>{mAccType === item ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon onClick={
                                        () => {
                                            setAccDialogLoader(true);
                                            getBalData(item);
                                        }
                                    } />}</></Typography>

                                ))
                            }
                        </div>
                }

            </>
        );
    };

    const handleDialogAccAction = () => {
        return (
            <>
                <DismissButton variant="contained" color="primary" onClick={() => setAccDialog(false)}>
                    Cancel
                </DismissButton>
            </>
        )
    }

    const inputAccountSelection = () => {
        return (
            <DialogBox
                showDialog={accDialog}
                title="Select Balance Type to see Today's Balance"
                onClose={() => setAccDialog(false)}
                dialogContent={renderDialogListAcc()}
                dialogActions={handleDialogAccAction()}
            >
            </DialogBox>

        )
    }

    return (
        <>
            <Grid container>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                    <div>
                        {inputAccountSelection()}
                        <Paper
                            elevation={0}
                            autoHeight
                            sx={{
                                display: "grid",
                                flexDirection: "row",
                                
                                backgroundColor:"#e9f3f5",
                                
                                borderRadius: 2,
                                padding: 1,
                            }}>
                            <Grid container spacing={1}
                                alignItems="center"
                                justifyContent="center" sx={{
                                    padding: 0.1,
                                }}>
                                {
                                    todaysBalSheetAlwd && <Grid item md={12} xs={12} sm={12}>
                                        <Grid container spacing={0}
                                            alignItems="center"
                                            justifyContent="center" sx={{
                                                padding: 0.1,
                                            }}>
                                            <Grid item md={1} xs={12} sm={3}>
                                                <Tooltip title="Change Balance Type.Click here" >
                                                    <p style={{ alignContent: "center", justifyContent: "center", alignItems: "center" }}><TipsAndUpdatesIcon
                                                        onClick={() => setAccDialog(true)}
                                                    /> {mAccType}: </p>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item md={1} xs={4} sm={3}>
                                                <Tooltip title="Today's Balance">
                                                    <Paper
                                                        elevation={0}
                                                        autoHeight
                                                        className="restaurant-todaybal-data-container"
                                                        sx={{
                                                            borderRadius: 4,
                                                            borderLeft: "5px solid #268a1b",
                                                            backgroundColor: "rgba(233, 231, 231, 0.849)",
                                                        }}>

                                                        <div className="balance-data-grid-container">
                                                            <ArrowDropUpIcon />
                                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "13px" }}>₹{todayBalData.balance}</p>
                                                        </div>
                                                        {
                                                            isMobile && <center>
                                                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "9px" }}>Today's Balance</p>
                                                            </center>
                                                        }
                                                    </Paper>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item md={1} xs={4} sm={3}>
                                                <Tooltip title="Today's Expense">
                                                    <Paper
                                                        elevation={0}
                                                        autoHeight
                                                        className="restaurant-todaybal-data-container"
                                                        sx={{
                                                            borderRadius: 4,
                                                            borderLeft: "5px solid #8a221b",
                                                            backgroundColor: "rgba(233, 231, 231, 0.849)",
                                                        }}>

                                                        <div className="balance-data-grid-container">
                                                            <ArrowDropDownIcon />
                                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "13px" }}>₹{todayBalData.expense}</p>
                                                        </div>
                                                        {
                                                            isMobile && <center>
                                                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "9px" }}>Today's Expense</p>
                                                            </center>
                                                        }
                                                    </Paper>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item md={1} xs={4} sm={3}>
                                                <Tooltip title="Today's Dues">
                                                    <Paper
                                                        elevation={0}
                                                        autoHeight
                                                        className="restaurant-todaybal-data-container"
                                                        sx={{
                                                            borderRadius: 4,
                                                            borderLeft: "5px solid #8a811b",
                                                            backgroundColor: "rgba(233, 231, 231, 0.849)",
                                                        }}>

                                                        <div className="balance-data-grid-container">
                                                            <CompressIcon />
                                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "13px" }}>₹{todayBalData.dues}</p>
                                                        </div>
                                                        {
                                                            isMobile && <center>
                                                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "10px" }}>Today's Dues</p>
                                                            </center>
                                                        }

                                                    </Paper>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {/* <Grid item md={2} xs={12} sm={6}>
                                    <div style={{ display: "flex", alignItems: "left" }}>
                                        <Typography component="div" fontSize={17} variant="p" color="#02056e" fontWeight='bold' sx={{
                                            mb: "18px", mr: "5px"
                                        }}>Search Bill Number: </Typography>
                                    </div>
                                </Grid> */}
                                <Grid item md={5} xs={12} sm={6}>
                                <Box sx={{ backgroundColor: 'white' }}>
                                    <Autocomplete id="search-select"
                                        options={listAllBill}
                                        autoHighlight
                                        variant="filled"
                                        fullWidth
                                        onChange={handleSelectBill}
                                        onBlur={(e) => setListAllBill([])}
                                        getOptionLabel={(option) => getSearchSelectDetails(option)}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                            >
                                                {getSearchSelectDetails(option)}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Search Bill or Mobile Number"
                                                onChange={handleBillSearch}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                        )}
                                    /></Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
                {
                    isDataLoaded ? <Grid item md={12} xs={12} sm={12} lg={12}>
                        {renderGrid()}
                    </Grid> : <Loader />
                }
                {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({ ...videoModel, show: false })} />}
                {videoModel.path && <SingleFab onClick={(e) => setVideoModel({ ...videoModel, show: true })} />}
            </Grid>
        </ >
    );
}
