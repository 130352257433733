import React, { useEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { Autocomplete, Box, Button, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { A2ZPAYMENTFAIL, A2ZPAYMENTRECV, A2ZVOUCHERCHECK, ADMINDOWNLOADCONFIGS, ADMINLOGIN, ADMINPACKAGES, AXIOS_TIMEOUT, CUSTOMIZEDCONFIG, FAILED, INVALIDEMAILOTP, INVALIDSMSOTP, OfferType, PERSISTAtZUSEr, REPGEN, SHOPEXIST, SUCCESS, USEREXIST, USEREXISTASEMP, VERIFYA2ZUSER, VERIFYA2ZUSEROTP, formatDateInReact, formatDateInSnp, isEmpty, isNumberEmpty, printDebugLog, printInfoLog } from "../../constants/constant";
import DialogBox from "../global/DialogBox";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../global/loader/Loader";
import { snpAxios } from "../global/api";
import utilities from "../../utilities/utilities";
import CancelIcon from '@mui/icons-material/Cancel';

export default function SignUp() {
    const todayFormattedDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const [isLoader, setLoader] = useState(false);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
        snackColor: '',
        snackVertical: '',
        snackHorizontal: '',
    };
    const ADMIN_LOGIN = {
        name: '', userEmail: '', passWord: '', id: '',
    };
    const ADMIN_CONFIGS = {
        packages: [],
        configs: [],
        reports: [],
        shopTypes: [],
        fullValidity: [],
        systemConfigPrice: 0.0,
        fullAdminPackages: [],
    };
    const CART_TYPE = {
        cartNo: 0,
        cartName: "",
    }

    const USER_CREATE = {
        city: "",
        state: "",
        cartType: 0,
        shop: "",
        phone: "",
        email: "",
        name: "",
        shopName: "",
        shopAddress: "",
        gstDetails: "",
        companyMail: "",
        companyName: "",
        companyWebAddress: "",
        p_img: "",
        validity: 1,
        hold: false,
        listConfigs: [],
        listPackages: [],
        listReports: [],
        startDate: todayFormattedDate,
        adminComments: "",
        userSpecialRequest: "",
        isDemo: false,
        selPackageId: "",
        voucherCode: "",
    };

    const PAYAUDIT = {
        offerType: OfferType.No_Offer,
        offerAmt: 0.0,
        amountFinalized: 0.0, //Include Offer Exclude GST
        haveDues: 0.0,
        remainingDues: 0.0,
        totalAmt: 0.0, //Exclude GST & Offer
        onlineBillPaidThrough: "",
        onlinePay: 0.0,
        cashPay: 0.0,
        cgst: 0.0,
        cgstAmt: 0.0,
        sgst: 0.0,
        sgstAmt: 0.0,
        roundOff: 0.0,
        gstEnable: true,
        finalAmt: 0.0, //Include GST
    };

    const OTPCONST = {
        phoneOtp: "", emailOtp: "", showDialog: false, loader: false, eMsg: ""
    }


    const VOUCHERMODEL = {
        offerType: OfferType.No_Offer, offerAmt: 0.0,applied:false,showDialog:false,
    }

    const VALIDITYMODEL = {
        featureName: "", validityTime: 0,
    }

    const PACKAGEMODEL = {
        selPackageId:"",
        featureName:"",
        featurePrice:0.0,
        offerType:0,
        offerAmt:0.0,
        listConfigs: [],
        listPackages: [],
        listReports: [],
    }

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [adminLogin, setAdminLogin] = useState(ADMIN_LOGIN);
    const [selectedCartType, setCartType] = useState(CART_TYPE);
    const [features, setFeatures] = useState(ADMIN_CONFIGS);
    const [a2zUserCreate, setUserCreate] = useState(USER_CREATE);
    const [payAudit, setPayAudit] = useState(PAYAUDIT);
    const [loginDialog, setLoginDialog] = useState(false);
    const [verifieduser, setVerifyUser] = useState(false);
    const [otpDialog, setOtpDialog] = useState(OTPCONST);
    const [voucherModel, setVoucherModel] = useState(VOUCHERMODEL);
    const [validityModel, setValidityModel] = useState(VALIDITYMODEL);
    const [packageModel, setPackageModel] = useState(PACKAGEMODEL);
    const [states, setStates] = useState([]);
    const [state, setState] = useState("");
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState("");

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

   const handleInputChange = (event) => {
        //console.log("handleInputChange",event);
        const { name, value } = event.target;
        //console.log("handleInputChange", name, value);
        if (name === "userEmail" || name === "passWord") {
            setAdminLogin({
                ...adminLogin,
                [name]: value,
            })
        }
    };

    useEffect(() => {
        console.log("Signup Page");
        getAdminConfis();
        getCountryList("");
    }, [storeApiUrl]);




    const doTotalMain = (add, amount, mOfferType, mOfferAmt, mGstEnable, mCgst, mSgst,mValidity) => {
        let tAmt = add ? (payAudit.totalAmt + amount) : (payAudit.totalAmt - amount);
        let mAmountFinalized = 0.0;
        let dOfferAmt = mOfferAmt;
        if (isNumberEmpty(amount))
            amount = 0.0;

        if (mOfferType === OfferType.Flat) {
            mAmountFinalized = tAmt - dOfferAmt;
        } else if (mOfferType === OfferType.Flat) {
            mAmountFinalized = tAmt - (tAmt * dOfferAmt / 100);
        } else {
            mAmountFinalized = tAmt;
            dOfferAmt = 0.0;
        }

        //GST Things
        let mCgstAmt = 0.0;
        let mSgstAmt = 0.0;
        let mFinalAmt = 0.0;
        if (mGstEnable) {
            mCgstAmt = mAmountFinalized * mCgst / 100;
            mSgstAmt = mAmountFinalized * mSgst / 100;
        }
        mFinalAmt = (mAmountFinalized + mCgstAmt + mSgstAmt) * mValidity;

        let mRoundOff = mFinalAmt.toFixed() - mFinalAmt;
        mFinalAmt = mFinalAmt + mRoundOff;
        printDebugLog("doTotalMain", tAmt, mAmountFinalized, mCgstAmt, mSgstAmt, mFinalAmt);
        setPayAudit({
            ...payAudit,
            cgst: mCgst, sgst: mSgst, offerAmt: dOfferAmt, offerType: mOfferType, totalAmt: tAmt, amountFinalized: mAmountFinalized,
            cashPay: 0.0, onlinePay: 0.0, gstEnable: mGstEnable, cgstAmt: mCgstAmt, sgstAmt: mSgstAmt, finalAmt: mFinalAmt,
            roundOff: mRoundOff.toFixed(2),
        })
    }

    const doTotal = (add, amount, mOfferType, mOfferAmt, mGstEnable) => {
        doTotalMain(add, amount, mOfferType, mOfferAmt, mGstEnable, payAudit.cgst, payAudit.sgst,a2zUserCreate.validity);
    }

    const changeValidity = (mValidity) => {
        doTotalMain(true, 0.0, payAudit.offerType, payAudit.offerAmt, payAudit.gstEnable, payAudit.cgst, payAudit.sgst,mValidity);
        setUserCreate({
            ...a2zUserCreate,validity:mValidity,
        })
    }
    

    const clearEveryThing = () => {
        setUserCreate(USER_CREATE);
        setPayAudit(PAYAUDIT);
        setVerifyUser(false);
        setVoucherModel(VOUCHERMODEL);
        setCartType(CART_TYPE);
        setSnpComponent(INITIAL_SNP_COMPONENT);
        setValidityModel(VALIDITYMODEL);
        setPackageModel(PACKAGEMODEL);
        setOtpDialog(OTPCONST);
        setAdminLogin(ADMIN_LOGIN);
    }

    const handleVerifyUser = () => {
        if (isEmpty(a2zUserCreate.phone)) {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Phone number cannot be empty",
                snackBarOpen: true,
                snackColor: '#240202',
                snackHorizontal: 'center',
                snackVertical: 'top'
            })
            return;
        }
        if (isEmpty(a2zUserCreate.email)) {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Email cannot be empty",
                snackBarOpen: true,
                snackColor: '#240202',
                snackHorizontal: 'center',
                snackVertical: 'top'
            })
            return;
        }
        let mUrl = storeApiUrl.BACKEND_BASE_URL + VERIFYA2ZUSER + "?phone=" + a2zUserCreate.phone + "&email=" + a2zUserCreate.email;
        if (!isEmpty(a2zUserCreate.gstDetails))
            mUrl = mUrl + "&gst=" + a2zUserCreate.gstDetails;
        setLoader(true);
        let data = {
        };
        axios.get(
            mUrl,
            data
        ).then(response => {
            setLoader(false);
            printDebugLog("responses => ", response.data);
            if (response.data === SUCCESS) {
                //setVerifyUser(true);
                setOtpDialog({
                    ...otpDialog, phoneOtp: "", emailOtp: "", showDialog: true,
                })
            } else if (response.data === USEREXIST)
                setSnpComponent({
                    ...snpComponent, snackBarMsg: "Phone (" + a2zUserCreate.phone + ") or email(" + a2zUserCreate.email + ") is already available.Try with different details",
                    snackBarOpen: true,
                    snackColor: '#240202',
                    snackHorizontal: 'center',
                    snackVertical: 'top'
                })
            else if (response.data === SHOPEXIST)
                setSnpComponent({
                    ...snpComponent, snackBarMsg: "Already this Gst(" + a2zUserCreate.gstDetails + ") is registered.",
                    snackBarOpen: true,
                    snackColor: '#240202',
                    snackHorizontal: 'center',
                    snackVertical: 'top'
                })
            else if (response.data === USEREXISTASEMP)
                setSnpComponent({
                    ...snpComponent, snackBarMsg: "Phone (" + a2zUserCreate.phone + ") or email(" + a2zUserCreate.email + ") is already available with one of the employee",
                    snackBarOpen: true,
                    snackColor: '#240202',
                    snackHorizontal: 'center',
                    snackVertical: 'top'
                })
            else setSnpComponent({
                ...snpComponent, snackBarMsg: "Some Internal Problem! Try again",
                snackBarOpen: true,
                snackColor: '#240202',
                snackHorizontal: 'center',
                snackVertical: 'top'
            })

        }).catch(error => {
            setLoader(false);
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    }

    const handleOtpCheck = () => {
        if (isEmpty(otpDialog.phoneOtp)) {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Otp for Phone cannot be empty",
                snackBarOpen: true,
                snackColor: '#240202',
                snackHorizontal: 'center',
                snackVertical: 'top'
            })
            return;
        }
        if (isEmpty(otpDialog.emailOtp)) {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Otp for Email cannot be empty",
                snackBarOpen: true,
                snackColor: '#240202',
                snackHorizontal: 'center',
                snackVertical: 'top'
            })
            return;
        }
        setOtpDialog({
            ...otpDialog, loader: true, eMsg: ""
        })
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + VERIFYA2ZUSEROTP + "?phone=" + a2zUserCreate.phone + "&email=" + a2zUserCreate.email;
        catgUrl = catgUrl + "&phoneOtp=" + otpDialog.phoneOtp + "&emailOtp=" + otpDialog.emailOtp;
        axios.get(
            catgUrl,
            {}
        ).then(response => {
            printDebugLog("handleOtpCheck responses => ", response.data);
            if (response.data === SUCCESS) {
                setOtpDialog(OTPCONST);
                setVerifyUser(true);
            } else if (response.data === INVALIDSMSOTP) {
                setOtpDialog({
                    ...otpDialog, loader: false, eMsg: "Invalid Otp for Phone",
                })
            } else if (response.data === INVALIDEMAILOTP) {
                setOtpDialog({
                    ...otpDialog, loader: false, eMsg: "Invalid Otp for Email",
                })
            } else {
                setOtpDialog({
                    ...otpDialog, loader: false, eMsg: "Internal error. Try Again",
                })
            }
        }).catch(error => {
            setOtpDialog(OTPCONST);
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    };

    const handleVoucherCodeCheck = () => {
        if (isEmpty(a2zUserCreate.voucherCode)) {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Voucher code cannot be empty",
                snackBarOpen: true,
                snackColor: '#240202',
                snackHorizontal: 'center',
                snackVertical: 'top'
            })
            return;
        }
        setVoucherModel({
            ...voucherModel,showDialog:true,
        })
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + A2ZVOUCHERCHECK + "?voucherCode=" + a2zUserCreate.voucherCode;
        axios.get(
            catgUrl,
            {}
        ).then(response => {
            printDebugLog("handleVoucherCodeCheck responses => ", response.data);
            if (response.data.status === SUCCESS) {
                setVoucherModel({
                    ...voucherModel,showDialog:false,applied:true,offerType:response.data.jcJson.offerType,
                    offerAmt:response.data.jcJson.offerAmt,
                })
                //setVerifyUser(true);
            }  else {
                setVoucherModel(VOUCHERMODEL);
                setSnpComponent({
                    ...snpComponent,snackBarMsg:"Invalid Voucher Code ",snackBarOpen:true,
                })
            }
        }).catch(error => {
            setLoader(false);
            console.log("handleVoucherCodeCheck error : ", error);
            //window.location.href = "#/error";
        });
    }

    const handleLogin = () => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + ADMINLOGIN;

        let filledData = {
            jcJson: {
                userEmail: adminLogin.userEmail,
                passWord: adminLogin.passWord,
            }
        };
        setLoader(true);
        setLoginDialog(false);
        axios({
            method: "POST",                 //POST_ACTION
            url: catgUrl,
            data: filledData,
            timeout: AXIOS_TIMEOUT
        }).then(response => {

            //console.log("responses => ", response.data);
            if (response.data.status === SUCCESS) {
                setAdminLogin({
                    ...adminLogin, ...response.data.jcJson
                });
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Welcome " + response.data.jcJson.name,
                    snackBarOpen: true,
                })
                getAdminConfis(response.data.jcJson.id);
            } else {
                setLoader(false);
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid Details You Idiot",
                    snackBarOpen: true,
                })
                setLoginDialog(true);
            }
        }).catch(error => {
            setLoader(false);
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Invalid Details You Idiot",
                snackBarOpen: true,
            })
            setLoginDialog(true);
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    }

    const getCountryList = (state) => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + "resto/show_country_list";
        if (!isEmpty(state)) {
            setLoader(true);
            catgUrl = catgUrl + "?state=" + state;
        }

        let data = {
        };
        axios.get(
            catgUrl,
            data
        ).then(response => {
            printDebugLog("getCountryList responses => ", response.data);
            if (response.data.status === SUCCESS) {
                if (!isEmpty(state)) {
                    setLoader(false);
                    setCities(response.data.jcJson.cities);
                } else
                    setStates(response.data.jcJson.states);
            } else {
                if (!isEmpty(state))
                    setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    }

    const getAdminPackages = (id) => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + ADMINPACKAGES + "/" + id;
        setLoader(true);
        let data = {
        };
        axios.get(
            catgUrl,
            data
        ).then(response => {
            setLoader(false);
            printDebugLog("getAdminPackages responses => ", response.data);
            if (response.data.status === SUCCESS) {
                const packages = isEmpty(response.data.jcJson.fulldataPackage) ? [] : response.data.jcJson.fulldataPackage.map(item => item.featureName);
                const reports = isEmpty(response.data.jcJson.fulldataReport) ? [] : response.data.jcJson.fulldataReport.map(item => item.featureName);
                const configs = isEmpty(response.data.jcJson.fulldataConfig) ? [] : response.data.jcJson.fulldataConfig.map(item => item.featureName);
                setPackageModel({
                    ...packageModel,selPackageId:id,
                    featureName:response.data.jcJson.featureName,
                    featurePrice:Number(response.data.jcJson.featurePrice),
                    offerType:Number(response.data.jcJson.offerType),
                    offerAmt:Number(response.data.jcJson.offerAmt),
                    listPackages: packages, listConfigs: configs, listReports: reports,
                })
                setUserCreate({
                    ...a2zUserCreate, listPackages: packages, listConfigs: configs, listReports: reports,
                    selPackageId: id, validity: Number(response.data.jcJson.validityTime),
                });
                setValidityModel({
                    ...validityModel, featureName: response.data.jcJson.validityName,
                    validityTime: Number(response.data.jcJson.validityTime),
                })
                doTotalMain(true, Number(response.data.jcJson.featurePrice),
                    Number(response.data.jcJson.offerType), Number(response.data.jcJson.offerAmt), payAudit.gstEnable,a2zUserCreate.validity);
            }
        }).catch(error => {
            setLoader(false);
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    }

    const a2zPayReceive = (mOrderId,mPayId,mSignature) => {
        setLoader(true);
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + A2ZPAYMENTRECV+"?orderId="+mOrderId+"&payId="+mPayId+"&signature="+mSignature;
        axios.get(
            catgUrl,
            {}
        ).then(response => {
            printDebugLog("a2zPayReceive responses => ", response.data);
            setLoader(false);
            if (response.data === SUCCESS) {
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Login",
                    negativeAction:"Close",
                    showDialog: true,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "Payment is received, Your account created Successfully!",
                    handleSnpCancel: () => {
                        clearEveryThing();
                        window.opener = null;
                        window.open("", "_self");
                        window.close();
                    },
                    handleSnpSubmit: () => {
                        clearEveryThing();
                        window.location.href = 'https://a2zbilling.snpnextgen.com/#/login';
                        window.opener = null;
                        window.open("", "_self");
                        window.close();
                        
                    },
                  });
            } else {
                printInfoLog("a2zPayReceive","Payment fail unexpected");
            }
        }).catch(error => {
            setLoader(false);
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    }

    const a2zPayFail = (mOrderId, mCancelled,mPayId,mCode, mDescription
        ,mSource,mStep, mReason,pgId,finalAmtInPaisa,mCurrency,payeeName) => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + A2ZPAYMENTFAIL+"?orderId="+mOrderId+"&cancelled="+mCancelled+"&payId="+mPayId+"&code="+mCode;
        catgUrl = catgUrl+"&description="+mDescription+"&source="+mSource+"&step="+mStep+"&reason="+mReason;
        setLoader(true);
        axios.get(
            catgUrl,
            {}
        ).then(response => {
            printDebugLog("a2zPayFail responses => ", response.data);
            setLoader(false);
            if (response.data === SUCCESS) {
                let msg = "";
                if (mCancelled == 1) 
                    msg = "Your payment is cancelled.Do you want to try again";
                else
                    msg = "Payment failed due to "+mReason+" (Error Code: "+mCode+")"
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Try again",
                    negativeAction:"Close",
                    showDialog: true,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: msg,
                    handleSnpCancel: () => {
                        clearEveryThing();
                        window.opener = null;
                        window.open("", "_self");
                        window.close();
                    },
                    handleSnpSubmit: () => {
                        displayRazorpay(pgId,finalAmtInPaisa,mOrderId,mCurrency,payeeName)                        
                    },
                  });
            } else {
                
            }
        }).catch(error => {
            setLoader(false);
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    }

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const displayRazorpay = async (pgId,finalAmtInPaisa,onlinePaymentTransactionId,mCurrency,payeeName) => {
        const res = await loadRazorpayScript();

        if (!res) {
            alert('Razorpay SDK failed to load. Please check your connection.');
            return;
        }

        const options = {
            key: pgId, // Replace with your Razorpay key ID
            amount: finalAmtInPaisa, // Amount is in currency subunits. 50000 refers to 50000 paise or INR 500
            currency: mCurrency,
            name: payeeName,
            description: 'Pay '+payAudit.finalAmt+" for new registeration in A2z",
            image: 'https://www.snpnextgen.com/logo.png', // Replace with your logo URL
            order_id: onlinePaymentTransactionId, // This is a sample Order ID. Replace with your generated Order ID from your backend.
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                printDebugLog("displayRazorpay success",response.razorpay_payment_id, 
                    response.razorpay_order_id ,response.razorpay_signature
                );
                a2zPayReceive(onlinePaymentTransactionId, response.razorpay_payment_id, response.razorpay_signature);
                //Handle success logic here
            },
            prefill: {
                name: a2zUserCreate.name,
                email: a2zUserCreate.email,
                contact: a2zUserCreate.phone,
            },
            notes: {
                address: a2zUserCreate.shopAddress,
            },
            theme: {
                color: '#00333E',
            },
            modal: {
                ondismiss: function () {
                    //alert('Payment process was cancelled.');
                    printDebugLog("displayRazorpay cancel by user");
                    a2zPayFail(onlinePaymentTransactionId,1,"","","","","","","","","","");
                    // Handle the modal dismiss event (like close button, back button, etc.)
                },
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            // alert(`Payment failed with error code: ${response.error.code}`);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
            printDebugLog("displayRazorpay failed",response.error.metadata.payment_id, 
                response.error.metadata.order_id ,response.error.description,response.error.source
                ,response.error.code,response.error.step,response.error.reason
            );
            a2zPayFail(onlinePaymentTransactionId,0,response.error.metadata.payment_id
                ,response.error.code,response.error.description,response.error.source,
                response.error.step,response.error.reason,pgId,finalAmtInPaisa,mCurrency,payeeName);
            // Handle the failed payment response here
        });
        rzp1.open();
    };

    const handleCreateOrder = () => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + PERSISTAtZUSEr;

        let filledData = {
            jcJson: {
                phone:a2zUserCreate.phone,
                email:a2zUserCreate.email,
                gstDetails:a2zUserCreate.gstDetails,
                shopName:a2zUserCreate.shopName,
                shopAddress:a2zUserCreate.shopAddress,
                city:a2zUserCreate.city,
                state:a2zUserCreate.state,
                cartType:a2zUserCreate.cartType,
                name:a2zUserCreate.name,
                startDate:a2zUserCreate.startDate,
                selPackageId:a2zUserCreate.selPackageId,
                voucherCode:a2zUserCreate.voucherCode,
                validity:a2zUserCreate.validity,
                listPackages:a2zUserCreate.listPackages,
                companyMail:a2zUserCreate.companyMail,
                companyName:a2zUserCreate.companyName,
                companyWebAddress:a2zUserCreate.companyWebAddress,
                listConfigs:a2zUserCreate.listConfigs,
                listReports:a2zUserCreate.listReports,
                userSpecialRequest:a2zUserCreate.userSpecialRequest,
                amountFinalized:payAudit.amountFinalized,
                offerAmt:payAudit.offerAmt,
                totalAmt:payAudit.amountFinalized,
                offerType:payAudit.offerType,
                cgst:payAudit.cgst,
                sgst:payAudit.sgst,
                cgstAmt:payAudit.cgstAmt,
                sgstAmt:payAudit.sgstAmt,
                gstEnable:payAudit.gstEnable,
                roundOff:payAudit.roundOff,
                finalAmt:payAudit.finalAmt,
            }
        };

        printDebugLog("handleCreateOrder",filledData);

            setLoader(true);
            snpAxios.post(
                catgUrl,
                filledData
            )
                .then((response) => {
                    printDebugLog("handleCreateOrder  => ", response.data.jcJson);
                    setLoader(false);
                    if (response.data.status === SUCCESS) {
                        if (!isEmpty(response.data.jcJson.pgId) && !isEmpty(response.data.jcJson.onlinePaymentTransactionId) && parseFloat(payAudit.finalAmt) > 0.0) {
                            let pgId = utilities.decode(response.data.jcJson.pgId);
                            displayRazorpay(pgId, response.data.jcJson.finalAmtInPaisa,
                                response.data.jcJson.onlinePaymentTransactionId, response.data.jcJson.currency, response.data.jcJson.payeeName
                            );
                        } else {
                            setSnpComponent({
                                ...snpComponent,
                                negativeReq: true,
                                positiveReq: true,
                                positiveAction: "Login",
                                negativeAction:"Close",
                                showDialog: true,
                                outSideClickDismiss: false,
                                cancelExtra: true,
                                requestType: 99,
                                subTitle: "Payment is received, Your account created Successfully!",
                                handleSnpCancel: () => {
                                    clearEveryThing();
                                    window.opener = null;
                                    window.open("", "_self");
                                    window.close();
                                },
                                handleSnpSubmit: () => {
                                    clearEveryThing();
                                    window.location.href = 'https://a2zbilling.snpnextgen.com/#/login';
                                    window.opener = null;
                                    window.open("", "_self");
                                    window.close();
                                    
                                },
                              });
                        }
                    }
                }).catch((error) => {
                    setPayAudit(PAYAUDIT);
                    setUserCreate({
                        ...a2zUserCreate,listConfigs:[],listReports:[],listPackages:[]
                    })
                    setLoader(false);
                    printInfoLog("errr ", error,a2zUserCreate,payAudit);
                    //window.location.href = "#/error";
                });
    };

    const isFeatureAvailable = (targetFilter, filter) => {
        if (filter.length === 0) return false;
        const index = filter.indexOf(targetFilter);
        //printLog("isFeatureAvailable", index , targetFilter , filter);
        return (index !== -1);
    };


    const getAdminConfis = () => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + ADMINDOWNLOADCONFIGS;
        setLoader(true);
        let data = {
        };
        axios.get(
            catgUrl,
            data
        ).then(response => {
            setLoader(false);
            printDebugLog("getAdminConfis responses => ", response.data);
            if (response.data.status === SUCCESS) {
                setFeatures({
                    ...features,
                    packages: response.data.jcJson.fulldataPackage,
                    reports: response.data.jcJson.fulldataReport,
                    configs: response.data.jcJson.fulldataConfig,
                    shopTypes: response.data.jcJson.fullShopType,
                    fullAdminPackages: response.data.jcJson.fullAdminPackages,
                    fullValidity: response.data.jcJson.fullValidity,
                    systemConfigPrice: Number(response.data.jcJson.systemConfigPrice),
                })
                if (!isEmpty(response.data.jcJson.cgst) && !isEmpty(response.data.jcJson.sgst))
                    doTotalMain(true, Number(response.data.jcJson.systemConfigPrice),
                        OfferType.No_Offer, 0.0, true, Number(response.data.jcJson.cgst), Number(response.data.jcJson.cgst),a2zUserCreate.validity);
                else
                    doTotalMain(true, Number(response.data.jcJson.systemConfigPrice),
                        OfferType.No_Offer, 0.0, true, 9.0, 9.0,a2zUserCreate.validity);
            }
        }).catch(error => {
            setLoader(false);
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    };


    const renderLoginDialog = () => {
        return (
            <>
                <Grid container spacing={1} justifyContent="center"
                    alignItems="center" sx={{
                        padding: 1,
                    }}>

                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="userEmail"
                            label="Login Email"
                            variant="filled"
                            size="small"
                            name="userEmail"
                            onChange={handleInputChange}
                            placeholder="Enter Login Name"
                            defaultValue={adminLogin.userEmail}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="passWord"
                            label="Password"
                            type="password"
                            variant="filled"
                            size="small"
                            name="passWord"
                            onChange={handleInputChange}
                            placeholder="Enter Your Password"
                            defaultValue={adminLogin.passWord}
                            fullWidth
                        />
                    </Grid></Grid>
            </>
        );
    };

    const renderOtpDialog = () => {
        return (
            <>
                <Grid container spacing={1} justifyContent="center"
                    alignItems="center" sx={{
                        padding: 1,
                    }}>

                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="phoneotp"
                            label="Enter Otp for Mobile"
                            variant="filled"
                            size="small"
                            name="phoneotp"
                            onChange={(e) => setOtpDialog({
                                ...otpDialog, phoneOtp: e.target.value,
                            })}
                            placeholder="Enter Otp that you have received in your mobile "
                            defaultValue={adminLogin.userEmail}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="phoneotp"
                            label="Enter Otp for Email"
                            variant="filled"
                            size="small"
                            name="phoneotp"
                            onChange={(e) => setOtpDialog({
                                ...otpDialog, emailOtp: e.target.value,
                            })}
                            placeholder="Enter Otp that you have received in your email "
                            defaultValue={adminLogin.userEmail}
                            fullWidth
                        />
                    </Grid>
                    {
                        otpDialog.loader && <Loader />
                    }
                    {
                        !isEmpty(otpDialog.eMsg) && <Typography variant="p" color="#cc0d0d">
                            {"Error : " + otpDialog.eMsg}
                        </Typography>
                    }
                </Grid>
            </>
        );
    };


    const renderDialogAction = () => {
        return (
            <>
                {
                    loginDialog && <Button variant="contained" color="primary" onClick={handleLogin}>
                        Login
                    </Button>
                }
                {
                    (otpDialog.showDialog && !otpDialog.loader) && <Button variant="contained" color="primary" onClick={handleOtpCheck}>
                        Submit
                    </Button>}
            </>
        )
    };

    

    const renderSnpContent = () => {
        return (
            <div>
                <DialogBox
                    showDialog={loginDialog}
                    title="Sign In"
                    outsideClickedDismiss={false}
                    // onClose={customerInputDialogClose}
                    dialogContent={renderLoginDialog()}
                    dialogActions={renderDialogAction()} />
                <DialogBox
                    showDialog={otpDialog.showDialog}
                    title="OTP!"
                    outsideClickedDismiss={false}
                    // onClose={customerInputDialogClose}
                    dialogContent={renderOtpDialog()}
                    dialogActions={renderDialogAction()} />
                {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img alt="signup" style={{ width: '40%', height: '40%' }} src="./signup.png" /></div> */}
                <Grid container spacing={1} display="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Grid item md={6} xs={12} sm={6}>
                        <Grid container spacing={1} justifyContent="center"
                            alignItems="center" sx={{
                                padding: 1,
                            }}>
                            <img alt="payment" style={{ width: '100%', height: '100%' }} src="./payment.webp" />
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12} sm={6} >
                        <Grid container spacing={2} component={Paper} elevation={1} sx={{ my: 1, py: 1 }}>
                            <Grid item md={12} xs={12} sm={12}>
                                <h4 style={{
                                    fontFamily: "monospace"
                                }}><u>Register Yourself...</u></h4>
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="phone"
                                    label="Enter Mobile Number"
                                    name="shop"
                                    disabled={verifieduser}
                                    placeholder="Enter Mobile Number"
                                    fullWidth
                                    defaultValue={a2zUserCreate.phone}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        const string = e.target.value;
                                        const regex = new RegExp("[0-9]");
                                        const isValid = regex.test(string);
                                        if (isValid) {
                                            setUserCreate({
                                                ...a2zUserCreate, phone: string,
                                            })
                                        }
                                    }

                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                +91
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="filled"
                                    size="small"
                                    type="number"
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="email"
                                    label="Enter Valid Email"
                                    name="email"
                                    placeholder="Enter Valid Email"
                                    fullWidth
                                    disabled={verifieduser}
                                    variant="filled"
                                    size="small"
                                    defaultValue={a2zUserCreate.email}
                                    type="email"
                                    onChange={(e) => setUserCreate({
                                        ...a2zUserCreate, email: e.target.value,
                                    })}
                                />
                            </Grid>

                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="gstDetails"
                                    label="Enter GST Number(optional)"
                                    name="gstDetails"
                                    placeholder="Enter GST Number(optional)"
                                    fullWidth
                                    disabled={verifieduser}
                                    defaultValue={a2zUserCreate.gstDetails}
                                    variant="filled"
                                    size="small"
                                    onChange={(e) => setUserCreate({
                                        ...a2zUserCreate, gstDetails: e.target.value,
                                    })}
                                />
                            </Grid>
                            {
                                !verifieduser && <Grid item xs={12} md={2} sm={4}>
                                    <Button variant="contained" color="primary" onClick={handleVerifyUser}>
                                        Search
                                    </Button>
                                </Grid>
                            }
                            {
                                verifieduser && <Grid item xs={12} md={2} sm={4}>
                                    <center>
                                        <Typography variant="p" color="#123a02" style={{ fontStyle: 'italic' }}>
                                            {"(Verified)"}
                                        </Typography>
                                    </center>
                                </Grid>
                            }
                        </Grid>
                        <Grid container spacing={1} component={Paper} elevation={1} sx={{ my: 1, p: 1 }}>
                            <Grid item md={12} xs={12} sm={12}>
                                <h4 style={{
                                    fontFamily: "monospace"
                                }}><u>Shop Details</u></h4>
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="name"
                                    label="Enter Your Name"
                                    name="name"
                                    placeholder="Enter Your Name"
                                    defaultValue={a2zUserCreate.name}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    onChange={(e) => setUserCreate({
                                        ...a2zUserCreate, name: e.target.value,
                                    })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="shopName"
                                    label="Enter Shop Name"
                                    name="shopName"
                                    placeholder="Enter Shop Name"
                                    defaultValue={a2zUserCreate.shopName}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    onChange={(e) => setUserCreate({
                                        ...a2zUserCreate, shopName: e.target.value,
                                    })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <Autocomplete
                                    id="cart-select"
                                    options={features.shopTypes}
                                    autoHighlight
                                    variant="filled"
                                    size="small"
                                    onChange={(e, cart) => {
                                        setCartType(cart);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.cartNo === value.cartNo}
                                    getOptionLabel={(option) => option.cartName}
                                    defaultValue={selectedCartType}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                        >
                                            {option.cartName}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Shop Type"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="companyName"
                                    label="Enter Company Name"
                                    name="companyName"
                                    placeholder="Enter Company Name"
                                    defaultValue={a2zUserCreate.companyName}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    onChange={(e) => setUserCreate({
                                        ...a2zUserCreate, companyName: e.target.value,
                                    })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="companyMail"
                                    label="Enter Company Email"
                                    name="companyMail"
                                    placeholder="Enter Company Email"
                                    defaultValue={a2zUserCreate.companyMail}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    type="email"
                                    onChange={(e) => setUserCreate({
                                        ...a2zUserCreate, companyMail: e.target.value,
                                    })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="companyWebAddress"
                                    label="Enter Web Address"
                                    name="companyWebAddress"
                                    placeholder="Enter Web Address"
                                    defaultValue={a2zUserCreate.companyWebAddress}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    type="url"
                                    onChange={(e) => setUserCreate({
                                        ...a2zUserCreate, companyWebAddress: e.target.value,
                                    })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <Autocomplete
                                    id="state-select"
                                    options={states}
                                    autoHighlight
                                    variant="filled"
                                    size="small"
                                    onChange={(e, mState) => {
                                        setUserCreate({
                                            ...a2zUserCreate, state: mState,
                                        })
                                        setState(mState);
                                        if (!isEmpty(mState)) {
                                            getCountryList(mState);
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    getOptionLabel={(option) => option}
                                    defaultValue={state}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                        >
                                            {option}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select State"
                                            type="none"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            {
                                (!isEmpty(a2zUserCreate.state) && !isEmpty(cities))
                                && <Grid item md={6} xs={12} sm={6}>
                                    <Autocomplete
                                        id="city-select"
                                        options={cities}
                                        autoHighlight
                                        variant="filled"
                                        size="small"
                                        onChange={(e, mCity) => {
                                            setUserCreate({
                                                ...a2zUserCreate, city: mCity,
                                            })
                                            setCity(mCity);
                                        }}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        getOptionLabel={(option) => option}
                                        defaultValue={city}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                            >
                                                {option}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select City"
                                                type="none"
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            }
                            <Grid item md={12} xs={12} sm={12}>
                                <TextField
                                    id="shopAddress"
                                    label="Enter Full Address"
                                    name="shopAddress"
                                    rows={2}
                                    multiline
                                    placeholder="Enter Full Address"
                                    defaultValue={a2zUserCreate.shopAddress}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    onChange={(e) => setUserCreate({
                                        ...a2zUserCreate, shopAddress: e.target.value,
                                    })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <Autocomplete
                                    id="package-select"
                                    options={features.fullAdminPackages}
                                    autoHighlight
                                    variant="filled"
                                    size="small"
                                    onChange={(e, p) => {
                                        if (p) {
                                            if (p.id === "Custom_Id") {
                                                setUserCreate({
                                                    ...a2zUserCreate,selPackageId:p.id,
                                                })
                                                setPackageModel({
                                                    ...packageModel, selPackageId: p.id,
                                                    featureName: p.featureName,
                                                })
                                            } else
                                                getAdminPackages(p.id)
                                        } else 
                                            setPackageModel(PACKAGEMODEL);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.selPackageId}
                                    getOptionLabel={(option) => option.featureName}
                                    defaultValue={packageModel}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                        >
                                            {option.featureName}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Your Package"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} md={6} sm={6}>
                                <Autocomplete
                                    multiple
                                    defaultValue={a2zUserCreate.listPackages}
                                    options={features.packages}
                                    disabled={(!isEmpty(packageModel.selPackageId) && "Custom_Id" !== packageModel.selPackageId)}
                                    limitTags={4}
                                    variant="filled"
                                    size="small"
                                    getOptionLabel={(option) => option.featureName + "(₹" + option.featurePrice + ")"}
                                    onChange={(event, newValue, reason, detail) => {
                                        printDebugLog("Autocomplete", newValue, reason, detail);
                                        if (reason === 'selectOption')
                                            doTotal(true, detail.option.featurePrice, payAudit.offerType, payAudit.offerAmt, payAudit.gstEnable);
                                        else if (reason === 'removeOption')
                                            doTotal(false, detail.option.featurePrice, payAudit.offerType, payAudit.offerAmt, payAudit.gstEnable);
                                        else if (reason === 'clear')
                                            doTotal(false, payAudit.totalAmt, OfferType.No_Offer, 0.0, payAudit.gstEnable);
                                        const featureNames = newValue.map(item => item.featureName);
                                        setUserCreate({
                                            ...a2zUserCreate, listPackages: featureNames
                                        });
                                    }}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                            {option.featureName + "(₹" + option.featurePrice + ")"}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Add Features"
                                            placeholder="Search"
                                        />
                                    )}
                                />
                            </Grid>
                            {
                                isFeatureAvailable(CUSTOMIZEDCONFIG,a2zUserCreate.listPackages) && 
                                <Grid item xs={12} md={6} sm={6}>
                                <Autocomplete
                                    multiple
                                    defaultValue={a2zUserCreate.listConfigs}
                                    options={features.configs}
                                    disabled={(!isEmpty(packageModel.selPackageId) 
                                            && "Custom_Id" !== packageModel.selPackageId)}
                                    limitTags={4}
                                    variant="filled"
                                    size="small"
                                    getOptionLabel={(option) => option.displayStr + "(₹" + option.featurePrice + ")"}
                                    onChange={(event, newValue, reason, detail) => {
                                        printDebugLog("Autocomplete", newValue, reason, detail);
                                        if (reason === 'selectOption')
                                            doTotal(true, detail.option.featurePrice, payAudit.offerType, payAudit.offerAmt, payAudit.gstEnable);
                                        else if (reason === 'removeOption')
                                            doTotal(false, detail.option.featurePrice, payAudit.offerType, payAudit.offerAmt, payAudit.gstEnable);
                                        else if (reason === 'clear')
                                            doTotal(false, payAudit.totalAmt, OfferType.No_Offer, 0.0, payAudit.gstEnable);
                                        const featureNames = newValue.map(item => item.featureName);
                                        setUserCreate({
                                            ...a2zUserCreate, listConfigs: featureNames
                                        });
                                    }}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                            {option.displayStr + "(₹" + option.featurePrice + ")"}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Add Configuration"
                                            placeholder="Search"
                                        />
                                    )}
                                />
                            </Grid>
                            }
                            {
                                isFeatureAvailable(REPGEN, a2zUserCreate.listPackages) &&
                                <Grid item xs={12} md={6} sm={6}>
                                    <Autocomplete
                                        multiple
                                        defaultValue={a2zUserCreate.listReports}
                                        options={features.reports}
                                        disabled={(!isEmpty(packageModel.selPackageId) 
                                            && "Custom_Id" !== packageModel.selPackageId)}
                                        limitTags={4}
                                        variant="filled"
                                        size="small"
                                        getOptionLabel={(option) => option.featureName + "(₹" + option.featurePrice + ")"}
                                        onChange={(event, newValue, reason, detail) => {
                                            printDebugLog("Autocomplete", newValue, reason, detail);
                                            if (reason === 'selectOption')
                                                doTotal(true, detail.option.featurePrice, payAudit.offerType, payAudit.offerAmt, payAudit.gstEnable);
                                            else if (reason === 'removeOption')
                                                doTotal(false, detail.option.featurePrice, payAudit.offerType, payAudit.offerAmt, payAudit.gstEnable);
                                            else if (reason === 'clear')
                                                doTotal(false, payAudit.totalAmt, OfferType.No_Offer, 0.0, payAudit.gstEnable);
                                            const featureNames = newValue.map(item => item.featureName);
                                            setUserCreate({
                                                ...a2zUserCreate, listReports: featureNames
                                            });
                                        }}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                {option.featureName + "(₹" + option.featurePrice + ")"}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Add Reports"
                                                placeholder="Search"
                                            />
                                        )}
                                    />
                                </Grid>
                            }
                            <Grid item md={6} xs={12} sm={6}>
                                <Autocomplete
                                    id="validity-select"
                                    options={features.fullValidity}
                                    autoHighlight
                                    variant="filled"
                                    size="small"
                                    onChange={(e, v) => {
                                        printDebugLog("validity",v);
                                        if (v) {
                                            setValidityModel({
                                                ...validityModel,featureName:v.featureName,
                                                validityTime:Number(v.featurePrice)
                                            })
                                            changeValidity(v.featurePrice);
                                        } else {
                                            setValidityModel(VALIDITYMODEL);
                                            changeValidity(1);
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) => option.featureName === value.featureName}
                                    getOptionLabel={(option) => option.featureName}
                                    defaultValue={validityModel}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                        >
                                            {option.featureName}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Time Frame"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <TextField
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    variant="filled"
                                    size="small"
                                    label="Enter Start Date"
                                    value={formatDateInReact(a2zUserCreate.startDate)}
                                    onChange={(event) => {
                                        const formatDateVal = formatDateInSnp(event.target.value, a2zUserCreate.startDate);
                                        //printLog("handleSetDate",name,value,formatDateVal);
                                        setUserCreate({
                                            ...a2zUserCreate,
                                            startDate: formatDateVal,
                                        })
                                    }}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            min: formatDateInReact(todayFormattedDate),
                                        },
                                    }} />
                            </Grid>
                            <Grid item md={12} xs={12} sm={12}>
                                <TextField
                                    id="userSpecialRequest"
                                    label="Any Special Request"
                                    name="userSpecialRequest"
                                    rows={2}
                                    multiline
                                    placeholder="Any Special Request"
                                    defaultValue={a2zUserCreate.userSpecialRequest}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    onChange={(e) => setUserCreate({
                                        ...a2zUserCreate, userSpecialRequest: e.target.value,
                                    })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="vouchercode"
                                    label="Enter Voucher Code"
                                    name="vouchercode"
                                    placeholder="Enter Voucher Code"
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    type="url"
                                />
                            </Grid>
                            {
                                voucherModel.showDialog && <Loader/>
                            }
                            {
                                voucherModel.applied && <Grid item xs={12} md={2} sm={4}>
                                    <center>
                                    <Typography variant="p" color="#123a02" style={{ fontStyle: 'italic' }}>
                                        (Applied){(<><CancelIcon onClick={()=> {
                                            setVoucherModel(VOUCHERMODEL);
                                            doTotal(true,0.0,OfferType.No_Offer,0.0,payAudit.gstEnable);
                                        }} /></>)}
                                    </Typography>
                                    </center>
                                </Grid>
                            }
                            {
                                (!voucherModel.showDialog || !voucherModel.applied) && <Grid item xs={12} md={2} sm={4}>
                                    <center>
                                        <Button variant="contained" color="primary" onClick={handleVoucherCodeCheck}>
                                            Check Voucher
                                        </Button>
                                    </center>
                                </Grid>
                            }
                            
                            <Grid item md={12} xs={12} sm={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" className="rate_card">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Offer Added</TableCell>
                                                <TableCell align="center">Total Amount</TableCell>
                                                <TableCell align="center">Cgst{"(" + payAudit.cgst + "%)"}</TableCell>
                                                <TableCell align="center">Sgst{"(" + payAudit.sgst + "%)"}</TableCell>
                                                <TableCell align="center">Round Off</TableCell>
                                                <TableCell align="center">Final Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                className="rate_card--row"
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="center">{payAudit.offerAmt}</TableCell>
                                                <TableCell align="center">{payAudit.totalAmt}</TableCell>
                                                <TableCell align="center">{payAudit.cgstAmt}</TableCell>
                                                <TableCell align="center">{payAudit.sgstAmt}</TableCell>
                                                <TableCell align="center">{(payAudit.roundOff >= 0 ? "(+)" : "(-)") + payAudit.roundOff}</TableCell>
                                                <TableCell align="center">{payAudit.finalAmt}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} md={2} sm={4}>
                            {/* (e)=>displayRazorpay("rzp_test_arItI5kjYlLu3f"
                                    ,"50000","","INR","A2z"
                                ) */}
                                <Button variant="contained" color="primary" onClick={handleCreateOrder}>
                                    Initiate Order
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <>
            <SnpComponent
                content={renderSnpContent()}
                showDialog={snpComponent.showDialog}
                isLoader={isLoader}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
                iconReq={snpComponent.iconReq}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={snpComponent.handleSnpCancel}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSubmit={snpComponent.handleSnpSubmit}
                gui={snpComponent.gui}
                // bgColor='#01333e'
                snackColor={snpComponent.snackColor}
                snackVertical={snpComponent.snackVertical}
                snackHorizontal={snpComponent.snackHorizontal}
            />
        </>
    );
}